import { getClassNames } from '@websolutespa/bom-core';
import { useAutosizeTextArea, useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmMicrophone, IconLlmPaperclip, IconLlmSend, IconLlmStop, IconLlmVolume, IconLlmVolumeX, IconWebsolute, IconX } from '@websolutespa/bom-mixer-icons';
import { useRef } from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { BusyIndicator } from '../BusyIndicator/busyindicator';
import { Blob } from '../Trigger/blob';
import { Suggestions } from './suggestions';

export const Prompt = () => {
  const label = useLabel();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const app = useLlm(state => state.app);
  const prompt = useLlm(state => state.prompt);
  const upload = useLlm(state => state.upload);
  const messages = useLlm(state => state.messages);
  const streaming = useLlm(state => state.streaming);
  const speakEnabled = useLlm(state => state.speakEnabled);
  const { setPrompt, recognizeStart, recognizeStop, abort, toggleSpeak, removeFile, hasSpeechRecognitionSupport, hasSpeechSynthesisSupport, addFile, hasUploadSupport } = useLlm(state => state.actions);
  const { send } = useLlmView(state => state.actions);

  useAutosizeTextArea(textAreaRef.current, prompt);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const prompt = event.target?.value;
    setPrompt(prompt);
  };

  const onSubmit = async (event?: React.FormEvent) => {
    event && event.preventDefault();
    if (!prompt) {
      return;
    }
    await send(prompt, (response) => {
      // console.log('Prompt.onChunk', response.chunks);
    }, (response) => {
      // console.log('Prompt.onEnd', response);
    });
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code == 'Enter' && !event.shiftKey) {
      onSubmit(event);
    }
  };

  const hasSpeechSynthesis = hasSpeechSynthesisSupport();
  const hasSpeechRecognition = hasSpeechRecognitionSupport();
  const hasUpload = hasUploadSupport();

  const appDisclaimer = app?.contents.disclaimer;
  const appDisclaimerFooter = app?.contents.disclaimerFooter;
  const disclaimer = appDisclaimerFooter ? (appDisclaimer || label('llm.disclaimer')) : '';

  return (
    <div className="llm__prompt">
      <div className="llm__container">
        {messages.length === 0 && (
          <Suggestions />
        )}
        <section id="llm-prompt">
          <form className="llm__prompt-form" onSubmit={onSubmit}>
            <div className={getClassNames('llm__prompt-input', {
              '-speech-synthesis': hasSpeechSynthesis,
              '-speech-recognition': hasSpeechRecognition,
              '-streaming': streaming,
            })}>
              {hasSpeechSynthesis && (
                <button className="llm__prompt-speak" type="button"
                  aria-label={speakEnabled ? label('llm.disableSpeak') : label('llm.enableSpeak')}
                  aria-pressed={speakEnabled}
                  onClick={toggleSpeak}
                >
                  {speakEnabled ? <IconLlmVolume /> : <IconLlmVolumeX />}
                </button>
              )}
              <label htmlFor="llm-prompt-textarea" className="llm__prompt-label">{label('llm.prompt')}</label>
              <textarea id="llm-prompt-textarea" className="llm__prompt-textarea" name="prompt" placeholder={app?.contents.promptPlaceholder} onKeyDown={onKeyDown} onChange={onChange} ref={textAreaRef} rows={1} value={prompt} aria-disabled={streaming}></textarea>
              <BusyIndicator />
              {hasUpload && (
                <div className="llm__prompt-upload-group">
                  <div className="llm__prompt-upload"
                    aria-label={label('llm.upload')}
                  >
                    <IconLlmPaperclip />
                  </div>
                  <input className="llm__prompt-upload-input" type="file" id="file-input" accept=".jpg, .jpeg, .png, .webp, .gif, .bmp, .tif, .tiff" onChange={addFile} />
                </div>
              )}
              {hasSpeechRecognition && (
                <button className="llm__prompt-microphone" type="button"
                  aria-label={label('llm.microphone')}
                  onMouseDown={() => recognizeStart()}
                  onMouseUp={() => recognizeStop()}
                  onTouchStart={() => recognizeStart()}
                  onTouchEnd={() => recognizeStop()}
                >
                  <IconLlmMicrophone />
                </button>
              )}
              {hasUpload && upload && (
                <button className="llm__prompt-upload-file" onClick={() => removeFile()} title={upload.file.name}>
                  <span>{upload.file.name}</span>
                  <IconX />
                  <img src={upload.base64} alt="" />
                </button>
              )}
            </div>
            {streaming ?
              <button className="llm__prompt-stop" type="button" onClick={abort}
                aria-label={label('llm.stopResponse')}
              >
                <Blob />
                <i><IconLlmStop /></i>
              </button>
              : <button className="llm__prompt-submit" type="submit"
                aria-disabled={!prompt}
                aria-label={label('llm.sendPrompt')}
              >
                <Blob />
                <i><IconLlmSend /></i>
              </button>}
          </form>
        </section>
      </div>
      <nav className="llm__prompt-footer">
        {disclaimer && (
          <div className="llm__prompt-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        )}
        <a className="llm__prompt-powered-by" href="https://www.websolute.com/" target="_blank" rel="noreferrer" title="Powered by Websolute">
          <span>powered by</span> <IconWebsolute aria-label="Websolute" />
        </a>
      </nav>
    </div>
  );
};
